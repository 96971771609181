import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const TitleStyled = styled.div`
  line-height: 3rem;
  ${(props) => props.theme.mixins.marginLeft(pxToRem(16))};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 5;
  padding: ${pxToRem(0)} ${pxToRem(24)};
  ${desktopMedia} {
    width: 100%;
    padding: ${pxToRem(0)} ${pxToRem(0)};
    h1 {
      font-size: ${pxToRem(50)};
    }
    ,
    h3 {
      font-size: ${pxToRem(30)};
      font-weight: 600;
    }
  }
`;
