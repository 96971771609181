import React from "react";
import { TitleStyled } from "./style";

const Title = props => {
  console.log(props)
  return (
    <TitleStyled>
      <h1>{props.title}</h1>
      <h3>{props.subTitle}</h3>

    </TitleStyled>
  );
};

Title.propTypes = {};

export default Title;
