import React from "react";
import { connect } from "react-redux";
import { updateLanguage } from "../../../../redux/actions";
import {
  HeaderContainer,
  LanguageSelectorContainer,
  LogoContainer,
  MenuContainer,
  MenuItem,
} from "./style";
import logo from "../../../../assets/images/DCT white logo.svg";
import Link from "../../atoms/Forms/Link";

const Header = (props) => {
  const setLanguageEnglish = () => {
    props.updateLanguage("en");
  };

  const setLanguageArabic = () => {
    props.updateLanguage("ar");
  };

  return (
    <HeaderContainer>
      <LogoContainer>
        <img src={logo} alt="logo" />
      </LogoContainer>

      <LanguageSelectorContainer>
      {/* <MenuContainer style={{margin:'1rem'}}>
        <MenuItem href="#">Home</MenuItem>
        <MenuItem href="#">Contact</MenuItem>
        <MenuItem href="#">AA</MenuItem>
      </MenuContainer> */}
        {props.lang === "ar" ? (
          <Link onChange={setLanguageEnglish} linkValue={"English"} />
        ) : (
          <Link onChange={setLanguageArabic} linkValue={"عربي"} />
        )}
      </LanguageSelectorContainer>
    </HeaderContainer>
  );
};

export const mapDispatchToProps = (dispatch) => {
  return {
    updateLanguage: (params) => dispatch(updateLanguage(params)),
  };
};

const mapStateToProps = (state) => {
  return {
    lang: state.languageReducer.lang,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
