import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
      position: absolute;
    padding: 20px 40px;
  width:-webkit-fill-available;
    justify-content: space-between;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.5); */
    color: #fff;
    z-index: 10;
  // box-shadow: ${props => props.theme.shadows[6]};
  padding: ${pxToRem(24)} ${pxToRem(24)};
  ${desktopMedia} {
    padding: ${pxToRem(24)} ${pxToRem(88)};
  }
`;

export const LanguageSelectorContainer = styled.div`
  width: ${pxToRem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: ${pxToRem(24)};
`;

export const LogoContainer = styled.div`
  img {
    width: 120px;
    ${desktopMedia} {
      width: 175px;
    }
  }
`;
export const MenuContainer = styled.div`
  display: flex;
  gap: ${pxToRem(20)};
  align-items: center;
`;

export const MenuItem = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: ${pxToRem(16)};
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
