import React from "react";
import { HeroBannerContainer, Overlay, HeroContent } from "./style";
import herobanner from "../../../../assets/images/herobanner.jpeg";

const HeroBanner = (props) => {
  return (
    <HeroBannerContainer>
      <Overlay />
      <img src={herobanner} alt="banner" />
              {/* <Title title={getLanguageValues(props.lang, "creativeVisaText")} subTitle={getLanguageValues(props.lang, "creativeVisaSubText")} /> */}

      <HeroContent>
        <h1>{props.title}</h1>
        <h3>{props.subTitle}</h3>
      </HeroContent>
    </HeroBannerContainer>
  );
};

export default HeroBanner;
