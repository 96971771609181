const multiLinguaValues = {
  salutationId: {
    labelEN: "Salutation",
    labelAR: "اللقب",
  },
  instructionHeadingText: {
    labelEN: "INSTRUCTIONS",
    labelAR: "التعليمات",
  },
  passportCodeText: {
    labelEN: "Passport Code",
    labelAR: "رمز جواز السفر",
  },
  preinstructionContent: {
    labelEN: `<div>
  <p>
    The Department of Culture and Tourism – Abu Dhabi endorses talented, experienced professionals that specialize in the following categories: 
    Visual Arts, Publishing, Performing Arts, Design & Crafts, Heritage, Gaming & e-Sports or Media.
  </p>
  <p>
    If you currently have an Emirates ID, please 
    <a href="https://www.tamm.abudhabi/en/life-events/individual/Identity-Citizenship-Human-Resources/Residency-Entry-Permit/RequestforGoldenVisaNomination" target="_blank">click here to apply</a>.
  </p>
  <p>
    If you are an international candidate with no Emirates ID, please 
    <a href="https://endorsementform.adcreativevisa.gov.ae/" target="_blank">click here to apply</a> and view the current DCT terms and conditions.
  </p>
</div>
`,
    labelAR: `<div dir="rtl" lang="ar">
  <p>
    تدعم دائرة الثقافة والسياحة – أبوظبي المحترفين الموهوبين وذوي الخبرة المتخصصين في الفئات التالية: 
    الفنون البصرية، النشر، الفنون الأدائية، التصميم والحرف اليدوية، التراث، الألعاب والرياضات الإلكترونية أو الإعلام.
  </p>
  <p>
    إذا كان لديك بطاقة هوية إماراتية، يرجى 
    <a href="https://www.tamm.abudhabi/en/life-events/individual/Identity-Citizenship-Human-Resources/Residency-Entry-Permit/RequestforGoldenVisaNomination" target="_blank">النقر هنا للتقديم</a>.
  </p>
  <p>
    إذا كنت مرشحاً دولياً بدون بطاقة هوية إماراتية، يرجى 
    <a href="https://endorsementform.adcreativevisa.gov.ae/" target="_blank">النقر هنا للتقديم</a> ومراجعة الشروط والأحكام الخاصة بدائرة الثقافة والسياحة.
  </p>
</div>
`,
  },
  instructionContentText: {
    labelEN: `The Department of Culture and Tourism – Abu Dhabi endorses talented, experienced professionals that specialize in the following categories: <strong> Visual Arts, Publishing, Performing Arts, Design & Crafts, Heritage, Gaming & e-Sports or Media</strong>.</b>
    <br><br>
     <p>
    If you currently have an Emirates ID, please 
    <a href="https://www.tamm.abudhabi/en/life-events/individual/Identity-Citizenship-Human-Resources/Residency-Entry-Permit/RequestforGoldenVisaNomination" target="_blank">click here to apply</a>.
  </p>
    <br><br>
   If you are an international candidate with no Emirates ID, Please read the <strong><a href=${process.env.REACT_APP_LINK_EndorsementRules_EN_Golden} target="_default" rel="no-referrer" >Endorsement Rules</a> </strong>before completing the endorsement application.
    <br>
    <br>
    <b>Start your endorsement application by clicking "proceed".</b>
    `,
    labelAR: `تعتمد دائرة الثقافة والسياحة – أبوظبي الموهوبين, المحترفين ذوي الخبرة المتخصصين في الفئات التالية:<strong> الفنون المرئية، النشر، الفنون المسرحية، التصميم والحرف اليدوية، التراث، الألعاب والرياضات الإلكترونية أو الإعلام</strong>. 
    <br><br>
     <p>
    إذا كان لديك بطاقة هوية إماراتية، يرجى 
    <a href="https://www.tamm.abudhabi/en/life-events/individual/Identity-Citizenship-Human-Resources/Residency-Entry-Permit/RequestforGoldenVisaNomination" target="_blank">النقر هنا للتقديم</a>.
  </p>
    <br><br>
    إذا كنت مرشحاً دولياً بدون بطاقة هوية إماراتية، يرجى قراءة 
    
    <strong><a href=${process.env.REACT_APP_LINK_EndorsementRules_AR_Golden} target="_default" rel="no-referrer" >قواعد الإعتماد</a></strong> قبل استكمال طلب الإعتماد. 
    
    <br>
    <br>
    <b>باشر بتقديم طلب الإعتماد الخاص بك بالنقر على زر "التقديم"</b>
    `,
  },
  declarationContentText: {
    labelEN: `I declare and agree that:
    <ol type="I">
    <li>I have read,  understood and agree to comply with the <strong><a href=${process.env.REACT_APP_LINK_EndorsementRules_EN_Golden} target="_default" rel="no-referrer" >Endorsement Rules</a></strong>;</li>
    <li>All information submitted (including attachments) as part of this application form and any other documentation submitted is complete and accurate to the best of my knowledge and belief;  </li>
    <li>All information submitted (including any attachments) may be disclosed to parties who will be involved in the processing of this application form and may also be disclosed by the Department of Culture and Tourism - Abu Dhabi to a third party as required;
    </li>
    <li>
    To the best of my knowledge and belief there is nothing in relation to my conduct, character or personal background of any nature that would adversely affect my application or may be damaging to the reputation of the Department of Culture and Tourism  - Abu Dhabi, Abu Dhabi or the UAE;
    </li>
    <li>The Department of Culture and Tourism - Abu Dhabi is authorized to display my name, contact details and visa categories on its or its subsidiaries’ websites and/or portals;</li>
    <li>I will pay in full all required fees and payments, including the sponsorship fees and health insurance fee (where relevant), prior to the issue of my Visa; </li>
    <li>The Department of Culture and Tourism - Abu Dhabi or any of its representatives may contact me regarding this application or any other related matter; and</li>
    <li>
    The Department of Culture and Tourism – Abu Dhabi can contact me with information related to creative and cultural news and events.
    </li>
    </ol>
    `,
    labelAR: `أصرّح وأُقرّ بما يلي:
    <ol type="I">
    <li>
    أنني قرأت واستوعبت وأوافق على الامتثال <strong><a href=${process.env.REACT_APP_LINK_EndorsementRules_AR_Golden} target="_default" rel="no-referrer" >لقواعد الإعتماد</a></strong>؛
    </li>
    <li>
    كافة المعلومات المقدمة كجزء من هذه الاستمارة (بما في ذلك المرفقات) وأي وثائق أخرى مقدمة كاملة, صحيحة ودقيقة حسب معرفتي واعتقادي؛ 
    </li>
    <li>
    يجــوز الإفصاح عن كافــة المعلومات المقدّمة (بما في ذلك أي مرفقات) إلى الجهات المسؤولة عن معالجة هذه الاستمارة، ويمكن لدائرة الثقافة والسياحة - أبوظبي الإفصاح عنها لأي طرف آخر حسبما هو ضروري؛
    </li>
    <li>
    حسب معرفتي واعتقادي لا يوجد أي شيء فيما يتعلق بسلوكي أو شخصيتي أو خلفيتي الشخصية من أي نوع من شأنه قد يؤثر سلبًا على طلبي أو قد يضر بسمعة دائرة الثقافة والسياحة - أبوظبي ، أبو ظبي أو الإمارات العربية المتحدة؛
    </li>
    <li>
    إن دائرة الثقافة والسياحة - أبوظبي مخولة بعرض اسمي وتفاصيل الاتصال الخاصة بي وفئة التصريح الخاصة بي على مواقع و / أو صفحات الدائرة أو الشركات التابعة لها؛
    </li>
    <li>
    أنني سأقوم بدفع الرسوم والدفعات المطلوبة بالكامل وتشمل رسوم الكفالة والتأمين الصحي (إن انطبق ذلك)، وذلك قبل إصدار الإقامة الخاص بي؛ 
    </li>
    <li>
    	يمكن لدائرة الثقافة والسياحة - أبوظبي أو أي من ممثليها التواصل معي بخصوص هذه الاستمارة أو أي من الموضوعات ذات الصلة؛ و
    </li>
    <li>
    يمكن لدائرة الثقافة والسياحة - أبوظبي التواصل معي بخصوص أخبار وفعاليات إبداعية وثقافية.
    </li>
    </ol>
    `,
  },
  firstName: {
    labelEN: "First Name",
    labelAR: "الاسم الأول",
  },
  middleName: {
    labelEN: "Middle Name(s)",
    labelAR: "اسم الأب",
  },
  surName: {
    labelEN: "Surname",
    labelAR: "القبيلة",
  },
  email: {
    labelEN: "Email",
    labelAR: "البريد الألكتروني",
  },
  genderId: {
    labelEN: "Gender",
    labelAR: "الجنس",
  },
  personalInformation: {
    labelEN: "Personal Information",
    labelAR: "المعلومات الشخصية",
  },
  nationalityAndPassport: {
    labelEN: "Nationality and Passport",
    labelAR: "الجنسية وجواز السفر",
  },
  dateOfBirth: {
    labelEN: "Date of Birth (MM/DD/YYYY)",
    labelAR: "تاريخ الميلاد (MM/DD/YYYY)",
  },
  birthCountryId: {
    labelEN: "Country of Birth",
    labelAR: "بلد الميلاد",
  },
  birthCity: {
    labelEN: "Place of Birth (City)",
    labelAR: "مكان الميلاد (المدينة)",
  },
  previousNationalityId: {
    labelEN: "Previous Nationality (where applicable)",
    labelAR: "الجنسية السابقة (إن وجدت)",
  },
  nationalityId: {
    labelEN: "Current Nationality",
    labelAR: "الجنسية الحالية",
  },
  passportTypeId: {
    labelEN: "Passport Type",
    labelAR: "نوع جواز السفر",
  },
  passportIssueCountryId: {
    labelEN: "Country of Issue",
    labelAR: "بلد الإصدار",
  },
  passportNumber: {
    labelEN: "Passport Number",
    labelAR: "رقم جواز السفر",
  },
  passportIssueCity: {
    labelEN: "Passport Issue Place",
    labelAR: "مكان إصدار جواز السفر",
  },
  passportIssueDate: {
    labelEN: "Passport Issue Date (MM/DD/YYYY)",
    labelAR: "تاريخ إصدار جواز السفر (MM/DD/YYYY)",
  },
  passportExpiryDate: {
    labelEN: "Passport Expiry date (MM/DD/YYYY)",
    labelAR: "تاريخ إنتهاء جواز السفر (MM/DD/YYYY)",
  },
  occupation: {
    labelEN: "Occupation",
    labelAR: "المهنة",
  },
  qualificationId: {
    labelEN: "Qualification",
    labelAR: "المؤهلات",
  },
  qualificationCountryId: {
    labelEN: "Qualification Country",
    labelAR: "بلد صدور المؤهلات",
  },
  educationLevelDetails: {
    labelEN: "Education Level Details",
    labelAR: "المستوى التعليمي",
  },
  maritalStatusId: {
    labelEN: "Marital Status",
    labelAR: "الحالة الاجتماعية",
  },
  motherName: {
    labelEN: "Mother`s Name",
    labelAR: "اسم الأم",
  },
  religionId: {
    labelEN: "Religion",
    labelAR: "الدين",
  },
  faithId: {
    labelEN: "Faith",
    labelAR: "المذهب",
  },
  uaeUnifiedNo: {
    labelEN: "UAE Unified No (on Visa)(where applicable)",
    labelAR: "الرقم الموحد الإماراتي (على التأشيرة) (إن وجد)",
  },
  uaeIdentityNumber: {
    labelEN: "Emirates ID Number (where applicable)",
    labelAR: "رقم بطاقة الهوية الإماراتية (إن وجد)",
  },
  hasUaeVisa: {
    labelEN: "Do you have an existing UAE visa?",
    labelAR: "هل لديك تأشيرة إماراتية حالياً؟",
  },
  sponsorName: {
    labelEN: "If YES, please name sponsor:",
    labelAR: "إذا كانت الإجابة نعم، يرجى ذكر اسم الكفيل:",
  },
  visaType: {
    labelEN: "What type of visa is it?",
    labelAR: "ما هو نوع التأشيرة؟",
  },
  visaExpiringDate: {
    labelEN: "Date that your existing UAE visa is expiring? (MM/DD/YYYY)",
    labelAR: "تاريخ انتهاء التأشيرة الحالية؟  (MM/DD/YYYY)",
  },
  hasLegalIssue: {
    labelEN:
      "Have you ever been convicted of a criminal offence for which you have been prosecuted in the UAE or elsewhere?",
    labelAR:
      "هل سبق إدانتك بجريمة جنائية تمت مقاضاتك بسببها في الإمارات أو في أي مكان آخر؟",
  },
  legalIssueDetails: {
    labelEN: "If YES, please provide details:",
    labelAR: "إذا كانت الإجابة نعم، يرجى كتابة التفاصيل",
  },
  havedependantsSponsor: {
    labelEN: "Do you have any dependants that you would like to sponsor?",
    labelAR: "هل لديك أي معالين ترغب في كفالتهم؟",
  },
  visaInformation: {
    labelEN: "Visa Information",
    labelAR: "معلومات التأشيرة",
  },
  occupationAndQualification: {
    labelEN: "Occupation and Qualification",
    labelAR: "المهنة والمؤهلات",
  },
  yes: {
    labelEN: "Yes",
    labelAR: "نعم",
  },
  no: {
    labelEN: "No",
    labelAR: "لا",
  },
  permanentContactInformation: {
    labelEN: "Permanent Contact Information in Home Country / Outside UAE",
    labelAR:
      "معلومات الاتصال الدائمة في البلد الأم / خارج دولة الإمارات العربية المتحدة",
  },
  homeCountryBuilding: {
    labelEN:
      "Home Country Residential Address: Apartment / Villa No, Building / Complex / Hotel",
    labelAR:
      "عنوان الإقامة في البلد الأم: الشقة / رقم الفيلا , البناية / المجمع / الفندق",
  },
  homeCountryStreet: {
    labelEN: "Home Country Residential Address: Street",
    labelAR: "عنوان الإقامة في البلد الأم: الشارع",
  },
  homeCountryCity: {
    labelEN: "Home Country Residential Address: City",
    labelAR: "عنوان الإقامة في البلد الأم: المدينة",
  },
  homeCountryState: {
    labelEN: "Home Country Residential Address: State",
    labelAR: "عنوان الإقامة في البلد الأم: الولاية",
  },
  homeCountryId: {
    labelEN: "Home Country Residential Address: Country",
    labelAR: "عنوان الإقامة في البلد الأم: الدولة",
  },
  homeCountryZipCode: {
    labelEN: "Home Country Residential Address: Zipcode",
    labelAR: "عنوان الإقامة في البلد الأم: الرمز البريدي",
  },
  homeCountryMobile: {
    labelEN: "Home Country Mobile number",
    labelAR: "رقم الهاتف المحمول في البلد الأم",
  },
  homeCountryOtherContact: {
    labelEN: "Home Country Other Contact number",
    labelAR: "رقم تواصل آخر في البلد الأم",
  },
  contactInformation: {
    labelEN: "Contact Information Inside UAE",
    labelAR: "معلومات الاتصال داخل الإمارات",
  },
  uaeBuilding: {
    labelEN:
      "UAE Residential Address: Apartment / Villa No, Building / Complex / Hotel",
    labelAR:
      "عنوان الإقامة في الإمارات : الشقة / رقم الفيلا , البناية / المجمع / الفندق",
  },
  uaeStreet: {
    labelEN: "UAE Residential Address: Street",
    labelAR: "عنوان الإقامة في الإمارات: الشارع",
  },
  uaeCity: {
    labelEN: "UAE Residential Address: City",
    labelAR: "عنوان الإقامة في الإمارات: المدينة",
  },
  uaeEmirateId: {
    labelEN: "UAE Residential Address: Emirate",
    labelAR: "عنوان الإقامة في الإمارات: الإمارة",
  },
  uaePoBox: {
    labelEN: "UAE Residential Address: PO Box",
    labelAR: "عنوان الإقامة في الإمارات: صندوق البريد",
  },
  uaeResidencePhoneNumber: {
    labelEN: "UAE Residence Phone Number",
    labelAR: "رقم الهاتف الأرضي في الإمارات",
  },
  uaeOtherContact: {
    labelEN: "UAE Office Phone Number",
    labelAR: "رقم هاتف المكتب في الإمارات",
  },
  uaeMobile: {
    labelEN: "UAE Mobile number",
    labelAR: "رقم الهاتف المحمول في الإمارات",
  },
  creativeVisaText: {
    labelEN: "Endorsement for Creatives",
    labelAR: "الإعتماد للمبدعين",
  },
  creativeVisaCategories: {
    labelEN: "CATEGORIES",
    labelAR: "فئة التأشيرة",
  },
  applicantTypeId: {
    labelEN: "Applicant Type",
    labelAR: "نوع التقديم",
  },
  visaCategoryId: {
    labelEN: "Which category are you applying for?",
    labelAR: "ماهي الفئة التي تقدم لها؟",
  },
  visaActivityId: {
    labelEN: "Which sub-category are you applying for?",
    labelAR: "ما هي الفئة الفرعية التي تقدم لها؟",
  },
  supportingDocuments: {
    labelEN: "SUPPORTING DOCUMENTS",
    labelAR: "المستندات الداعمة",
  },
  supportingDocumentsDisclaimer: {
    labelEN: `Please refer to the  <a href=${process.env.REACT_APP_LINK_EndorsementRules_EN_Golden} target="_default" rel="no-referrer" >Endorsement Rules</a> for a list of the required supporting documentation. <br/>If you wish to include links to online articles or IMDb profiles etc., please paste the link into a Word document and upload the document as a "Supporting Document".`,
    labelAR: `يرجى مراجعة <a href=${process.env.REACT_APP_LINK_EndorsementRules_AR_Golden} target="_default" rel="no-referrer" >قواعد الاعتماد</a> للاطلاع على المستندات المطلوبة. <br/>  إذا كنت ترغب في تضمين روابط لمقالات عبر الإنترنت أو ملفات تعريف  IMDB أوإلخ… فيرجى إرفاق الرابط في برنامج Word وقم بتحميل المستند كـ "مستند داعم".`,
  },
  PersonalInformationDisclaimer: {
    labelEN: `* mandatory field`,
    labelAR: `*حقل الزامي`,
  },
  attachment_Passport: {
    labelEN: "Upload Passport",
    labelAR: "تحميل جواز",
  },
  attachment_Colour_photo: {
    labelEN: "Upload Colour Photo (white background)",
    labelAR: "تحميل صورة ملونة (خلفية بيضاء)",
  },
  attachment_Dependant_passport: {
    labelEN: "Dependant Passport",
    labelAR: "جواز السفر المعال",
  },
  attachment_Health_Insurance: {
    labelEN: "Health Insurance",
    labelAR: "التأمين الصحي",
  },
  attachment_Copy_UAE_HEALTH_INSURANCE: {
    labelEN:
      "Upload Copy of valid UAE health insurance card (where applicable)",
    labelAR: "تحميل نسخة من بطاقة التأمين الصحي سارية المفعول  (إن وجد)",
  },
  acclaimedApplicants: {
    labelEN: "Acclaimed Applicants",
    labelAR: "المقدمون البارزون",
  },
  attachment_Upload_evidence: {
    labelEN: "Upload evidence of acclaimed status",
    labelAR: "تحميل دليل يثبت الفئة ",
  },
  attachment_Additional_Supporting_Docs: {
    labelEN: "Upload Additional Supporting Documents",
    labelAR: "تحميل ملفات داعمة اضافية ",
  },
  attachment_Supporting_doc1: {
    labelEN: "Upload Supporting Document 1",
    labelAR: "تحميل الملفات الداعمة 1 ",
  },
  attachment_Supporting_doc2: {
    labelEN: "Upload Supporting Document 2",
    labelAR: "تحميل الملفات الداعمة 2 ",
  },
  attachment_Supporting_doc3: {
    labelEN: "Upload Supporting Document 3",
    labelAR: "تحميل الملفات الداعمة 3 ",
  },
  attachment_EvidenceWorkAUH:{
    labelEN: "Evidence of previous/upcoming work in Abu Dhabi",
    labelAR: "تحميل دليل على الأعمال السابقة أو المستقبلية في ابوظبي",
  },
  attachment_Uae_Visa: {
    labelEN: "Upload UAE Visa (where applicable)",
    labelAR: "تحميل تأشيرة إقامة إماراتية (إن وجد)",
  },
  attachment_EmiratesId: {
    labelEN: "Upload Emirates ID (where applicable)",
    labelAR: "تحميل بطاقة الهوية الإماراتية (إن وجد) ",
  },
  talentedApplicants: {
    labelEN: "Talented Applicants",
    labelAR: "المتقدمون الموهوبون",
  },
  attachment_Letter_of_Intent: {
    labelEN: "Letter of Intent",
    labelAR: "خطاب رسمي",
  },
  attachment_Portfolio_of_Work: {
    labelEN: "Portfolio of Work / Curriculum Vitae",
    labelAR: "ملف أعمالك / السيرة الذاتية",
  },
  attachment_Reference_Letter: {
    labelEN: "Reference Letter",
    labelAR: "رسالة مرجعية ",
  },
  attachment_Evidence_of_degree: {
    labelEN:
      "Evidence of degree / qualification from recognized institute within the field",
    labelAR: "مؤهل من معهد معتمد في المجال",
  },
  attachment_Other_relevant_documents: {
    labelEN: "Other relevant documents /links (discretion of applicant)",
    labelAR: "المستندات الأخرى ذات الصلة (بحسب مقدم الطلب)",
  },
  declarationText: {
    labelEN: "DECLARATION",
    labelAR: "إقرار",
  },
  name: {
    labelEN: "Name",
    labelAR: "الاسم",
  },
  sign_date_form: {
    labelEN: "Date  (MM/DD/YYYY)",
    labelAR: "التاريخ  (MM/DD/YYYY)",
  },
  signature: {
    labelEN: "Signature",
    labelAR: "التوقيع",
  },
  privacyPolicyCheckboxLinkText: {
    labelEN: `I have read and accept the terms of the Department of Culture and Tourism - Abu Dhabi's <strong><a href=${process.env.REACT_APP_LINK_PRIVACY_POLICY_EN_Golden} target="_default" rel="no-referrer" > privacy policy</a></strong>.`,
    labelAR: `لقد قرأت وقبلت شروط <strong><a href=${process.env.REACT_APP_LINK_PRIVACY_POLICY_AR_Golden} target="_default" rel="no-referrer" >سياسة الخصوصية</a></strong> لدائرة الثقافة والسياحة - أبوظبي `,
  },
  terms: {
    labelEN:
      "By registering my information, I agree to be contacted by the Department of Culture and Tourism – Abu Dhabi and/or its third party representatives.",
    labelAR:
      "من خلال تسجيل معلوماتي، أوافق على أن يتم الاتصال بي من قبل دائرة الثقافة والسياحة - أبوظبي و/أو ممثلي الطرف الثالث.",
  },
  submitText: {
    labelEN: "Submit",
    labelAR: "إرسال",
  },
  requiredFieldText: {
    labelEN: "This field is required",
    labelAR: "هذا الحقل مطلوب",
  },
  successMessage: {
    labelEN:
      "Your request is submitted sucessfully. Your referrence number is: ",
    labelAR:
      "شكرًا على تقديم طلب الإعتماد الخاص بك. سنتواصل معك قريبًا. رقمك المرجعي هو",
  },
  apiErrorMessage: {
    labelEN: "Something went wrong. Please check your files size and try to reduce it or send a file which contains a link for shared files",
    labelAR: "حدث خطأ.يرجى التحقق من حجم ملفاتك ومحاولة تقليلها، أو إرسال ملف يحتوي على رابط للملفات المشتركة.",
  },
  dateErrorMessage: {
    labelEN: "Specified date isn't valid please enter a valid date",
    labelAR: "التاريخ غير صحيح, من فضلك أدخل تاريخ صحيح",
  },
  xssErrorMessage: {
    labelEN: "one or more fields is containing malicious script",
    labelAR: "يحتوي حقل واحد أو أكثر على نصوص برمجية ضارة",
  },
  proceedText: {
    labelEN: "Proceed",
    labelAR: "تقديم",
  },
  contactInfoText: {
    labelEN: `<table class="address_tbl">
      <tbody>
          <tr>
              <td colspan="2">PO Box 94000, Abu Dhabi UAE</td>
          </tr>
          <tr>
              <td class="mob_t_sty_lft">Tel:</td>
              <td class="mob_t_sty_rgt"><span>+971 2 444 0444</span></td>
          </tr>
          <tr>
              <td class="mob_t_sty_lft">Fax:</td>
              <td class="mob_t_sty_rgt"><span>+971 2 444 0400</span></td>
          </tr>
          <tr>
              <td class="mob_t_sty_lft">E-mail:</td>
              <td class="mob_t_sty_rgt"><a href="mailto:info@dctabudhabi.ae">info@dctabudhabi.ae</a></td>
          </tr>
      </tbody>
  </table>`,
    labelAR: `<table class="address_tbl">
    <tbody>
        <tr>
            <td colspan="2">صندوق بريد 94000, أبوظبي الإمارات العربية المتحدة</td>
        </tr>
        <tr>
            <td class="mob_t_sty_lft">هاتف:</td>
            <td class="mob_t_sty_rgt"><span>+971 2 444 0444</span></td>
        </tr>
        <tr>
            <td class="mob_t_sty_lft">فاكس:</td>
            <td class="mob_t_sty_rgt"><span>+971 2 444 0400</span></td>
        </tr>
        <tr>
            <td class="mob_t_sty_lft">البريد الإلكتروني:</td>
            <td class="mob_t_sty_rgt"><a href="mailto:info@dctabudhabi.ae">info@dctabudhabi.ae</a></td>
        </tr>
    </tbody>
</table>`,
  },
  contactAddrerssText: {
    labelEN: `<span>For any technical issues &amp; support please contact us using below details<br>
      Phone No: +97125995106 <br>
      Email Address: <a href="mailto:esupport@dctabudhabi.ae">esupport@dctabudhabi.ae</a>
      </span>`,
    labelAR: `<span>لأية مشاكل تقنية ودعم يرجى الاتصال بنا باستخدام التفاصيل أدناه<br>
    رقم الهاتف: +97125995106 <br>
    عنوان البريد الإلكتروني: <a href="mailto:esupport@dctabudhabi.ae">esupport@dctabudhabi.ae</a>
    </span>`,
  },
  followUsText: {
    labelEN: "Follow us",
    labelAR: "تابعنا",
  },
  contactText: {
    labelEN: "Contact",
    labelAR: "الاتصال",
  },
  officesText: {
    labelEN: "International Offices",
    labelAR: "المكاتب الدولية",
  },
  privacyPolicyText: {
    labelEN: "Privacy Policy",
    labelAR: "سياسة الخصوصية",
  },
  quickLinksText: {
    labelEN: "Quick Links",
    labelAR: "روابط سريعة",
  },
  golden: {
    labelEN: "Golden",
    labelAR: "الذهبية",
  },

  creative: {
    labelEN: "Creative",
    labelAR: "الإبداعية",
  },
  termsAndConditionText: {
    labelEN: "Terms & Conditions",
    labelAR: "الشروط والأحكام",
  },
  copyRightText: {
    labelEN:
      "Copyright © 2021 Department of Culture and Tourism, all rights reserved.",
    labelAR: "حقوق الطبع © 2021 وزارة الثقافة والسياحة، وجميع الحقوق محفوظة.",
  },
  homePageText: {
    labelEN:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    labelAR:
      "Lorem Ipsum هو ببساطة نص وهمية من صناعة الطباعة و typesetting.كان لوريم إيبسوم النص الوهمي القياسي في الصناعة منذ القرن التاسع عشر ، عندما أخذت طابعة غير معروفة مطبخًا من النوع وتدافعت عليه لصنع كتاب عينة من النوع.وقد نجا ليس فقط خمسة قرون ، ولكن أيضا قفزة في التنضيد الإلكتروني ، وتبقى دون تغيير أساسا.وقد شاع في 1960s مع الافراج عن أوراق Letraset التي تحتوي على مقاطع لوتريم Ipsum ، ومؤخرا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات من لوريم إبسوم.",
  },
  instructionNewContentText: {
    labelEN: `
      The Department of Culture and Tourism Abu Dhabi endorses talented, experienced professionals across diverse creative fields, including:  
      <ul>
        <li>Visual Arts</li>
        <li>Publishing</li>
        <li>Performing Arts</li>
        <li>Design & Crafts</li>
        <li>Heritage</li>
        <li>Gaming & E-Sports</li>
        <li>Media</li>
      </ul>
      
      <strong>Get Started:</strong> <br/>
      <strong>1. For UAE Residents (with Emirates ID)</strong>  <br/>
      If you hold a valid Emirates ID, you can continue the process via the designated platform.  
      <br>
<strong><a class="responsive-button" 
  href=${process.env.REACT_APP_TAMM_REDIERCT} target="_blank" rel="noopener noreferrer">
  Apply Using Emirates ID
</a></strong>
      <br><hr style="border:0.5px solid #D0D0D0; margin:20px 0px 20px 0px">
      <strong>2. For International Applicants (without Emirates ID)</strong>  <br/>
      If you do not have an Emirates ID, you can start your application here.<br/>
     
    `,
    labelAR: `
      تعتمد دائرة الثقافة والسياحة – أبوظبي الموهوبين والمحترفين ذوي الخبرة في مجالات إبداعية متنوعة، بما في ذلك:  
      <ul>
        <li>الفنون المرئية</li>
        <li>النشر</li>
        <li>الفنون المسرحية</li>
        <li>التصميم والحرف اليدوية</li>
        <li>التراث</li>
        <li>الألعاب والرياضات الإلكترونية</li>
        <li>الإعلام</li>
      </ul>
      
      <strong>البدء:</strong> <br/>
      <strong>1. المقيمون في الإمارات (بهوية إماراتية)</strong>  <br/>
      إذا كنت تحمل هوية إماراتية سارية، يمكنك متابعة العملية عبر المنصة المخصصة.  
      <br>
      <strong><a class="responsive-button" 
        href=${process.env.REACT_APP_TAMM_REDIERCT} target="_blank" rel="noopener noreferrer">التقديم باستخدام الهوية الإماراتية</a></strong>
      <br><hr style="border:0.5px solid #D0D0D0; margin:20px 0px 20px 0px">
      <strong>2. المتقدمون الدوليون (بدون هوية إماراتية)</strong>  <br/>
      إذا لم يكن لديك هوية إماراتية، يمكنك بدء تقديم طلبك هنا.<br/>
        
    `,
  },
  

  copyRightNewText: {
    labelEN: "Privacy Policy | Terms and Condition",
    labelAR: "سياسة الخصوصية | الشروط والأحكام",
  },
  proceedYourApplication: {
    labelEN: "Start Your Application",
    labelAR: "انتقل إلى التطبيق",
},
creativeVisaSubText: {
  labelEN: "Empowering Creatives to Build a Future in the UAE",
  labelAR:
    "تمكين المبدعين لبناء مستقبل أفضل في دولة الإمارات العربية المتحدة ",
},
};

export const getLanguageValues = (lang, fieldName) => {
  const selector = lang === "ar" ? "labelAR" : "labelEN";
  return multiLinguaValues[fieldName][selector];
};
