import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
export const RichTextStyle = styled.div`
  line-height: ${pxToRem(40)};

  /* Apply styles to <a> tag inside the div */
  .responsive-button {
    background: #f77860;
    width: 20%; /* Default for desktop */
    display: inline-block;
    text-align: center;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    margin: 1rem 0 1rem 0;
    transition: background 0.3s ease-in-out;

    &:hover {
      background: #e56750; /* Slightly darker shade */
    }

  /* Tablets (iPads & iPad Mini) */
  @media (max-width: 1024px) {
    width: 40%;
  }

  /* iPad Mini (768px - 834px) */
  @media (min-width: 768px) and (max-width: 834px) {
    width: 50%;  /* Adjust width for iPad Mini */
  }

  /* Mobile Devices */
  @media (max-width: 767px) {
    width: 100% !important;
    margin: 0 !important;
  }
`;
