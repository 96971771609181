import React, { useState } from "react";

import Button from "../../atoms/Forms/Button";
import Header from "../../molecules/Header";
import HeroBanner from "../../molecules/HeroBanner";
import CreativeVisaPage from "../CreativeVisaPage";
import { AppContainer, HomeContainer, HomeTextContainer } from "./style";
import { HomePageData } from "./data";
import { getLanguageValues } from "../../../Utils/MultiLingualValues";
import { pushAnaylyticsData } from "../../../Utils/helperFunctions";
import Title from "../../atoms/Title";
import Footer from "../../molecules/Footer";
import ReadOnlyField from "../../atoms/ReadOnlyField";
import CheckBox from "../../atoms/Forms/CheckBox";
import { Helmet } from "react-helmet";

const TITLE = "Creative Visa app";

const HomePage = (props) => {
  const [proceedClicked, setProceedClicked] = useState(false);
  const [privacyClicked, setprivacyClicked] = useState(false);

  const data = HomePageData(props.lang);

  const onProceedClick = () => {
    pushAnaylyticsData("clicked", "Proceed Button");
    setProceedClicked(true);
  };

  const onCheckBoxChange = (event) => {
    if (event.target) {
      setprivacyClicked(event.target.checked);
    }
  };

  return (
    <AppContainer>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <Header />
      <div>
        <HeroBanner title={getLanguageValues(props.lang, "creativeVisaText")} subTitle={getLanguageValues(props.lang, "creativeVisaSubText")} />
        {/* <Title title={getLanguageValues(props.lang, "creativeVisaText")} subTitle={getLanguageValues(props.lang, "creativeVisaSubText")} /> */}
        {!proceedClicked ? (
          <HomeContainer>
            <HomeTextContainer>
              <div className="text-container">
                <ReadOnlyField data={data.homePageText} />
                {/* {getLanguageValues(props.lang, 'homePageText')} */}
              </div>
              {/* <CheckBox
                data={data.privacyCheckBox}
                lang={props.lang}
                onChange={onCheckBoxChange}
              /> */}
             <div className="container" >
            <Button
                data={data.proceedButtonData}
                onClick={onProceedClick}
              />
            </div>
            </HomeTextContainer>
            
            
          </HomeContainer>
          
        ) : (
          <CreativeVisaPage lang={props.lang} />
        )}
      </div>
      <Footer lang={props.lang} />
    </AppContainer>
  );
};

export default HomePage;
