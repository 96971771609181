import React from 'react';

import { getLanguageValues } from '../../../Utils/MultiLingualValues'
import { FooterBottomSectionContainer, FooterContainer, OfficesSection, QuickLinkSection, ContactSection, FollowSection } from './style'
import footerMapImage from "../../../../assets/images/footer_map.png";
import linkdinImage from "../../../../assets/images/linkdin_icn.png";
import twitterImage from "../../../../assets/images/twitter_icn.png";
import instagramImage from "../../../../assets/images/instagram_icn.png";
import facebookImage from "../../../../assets/images/facebook_icn.png";
import youtubeImage from "../../../../assets/images/youtube_icn.png";
import logo from "../../../../assets/images/footer_tca_logo.png";
import RichText from '../../atoms/RichText';

const Footer = (props) => {
	const { lang } = props;
	const quickLinksText = process.env.REACT_APP_FOOTER_QUICK_LINKS_TEXT.split(',');
	const quickLinksLink = process.env.REACT_APP_FOOTER_QUICK_LINKS_LINK.split(',');
	const followLinks = [
		{link: 'https://www.linkedin.com/company/dctabudhabi', src: linkdinImage, alt: 'linkdin_icn' },
		{link: 'https://twitter.com/dctabudhabi', src: twitterImage, alt: 'twitter_icn' },
		{link: 'https://www.instagram.com/dctabudhabi/', src: instagramImage, alt: 'instagram_icn' },
		{link: 'https://www.facebook.com/dctabudhabi', src: facebookImage, alt: 'facebook_icn' },
		{link: 'https://youtube.com/c/dctabudhabi', src: youtubeImage, alt: 'youtube_icn' },
	];

	const renderLinks = () => {
		return quickLinksText.map((text, index) => {
			return <div className="links" key={`${text}_${Math.random()}`}><a href={quickLinksLink[index]} target="_blank" rel="noreferrer">{text}</a></div>
		});
	}

	const renderFollowUs = () => {
		return followLinks.map(link => {
			return <li><a href={link.link} target="_blank" rel="noreferrer"><img src={link.src} alt={link.alt} /></a></li>
		})
	}

	return (
		<>
		{/* <FooterContainer>
			<QuickLinkSection>
				<h4>{getLanguageValues(lang, 'quickLinksText')}</h4>
				{quickLinksText && renderLinks()}
			</QuickLinkSection>
			<OfficesSection>
				<h4>{getLanguageValues(lang, 'officesText')}</h4>
				<a href="https://tcaabudhabi.ae/en/who.we.are/our.offices.aspx" target="_blank" rel="noreferrer">
					<img src={footerMapImage} alt="offices" />
				</a>
			</OfficesSection>
			<ContactSection>
				<h4>{getLanguageValues(lang, 'contactText')}</h4>
				<RichText content={getLanguageValues(lang, 'contactInfoText')} />
				<RichText content={getLanguageValues(lang, 'contactAddrerssText')} />
			</ContactSection>
			<FollowSection>
				<h4>{getLanguageValues(lang, 'followUsText')}</h4>
				<ul>
					{renderFollowUs()}
				</ul>
				<img src={logo} alt="logo" />
			</FollowSection>
		</FooterContainer> */}
		<FooterBottomSectionContainer>
				<div>{getLanguageValues(lang, 'copyRightNewText')}</div>
				{/* <ul>
					<li><a href={`${lang === 'ar' ? process.env.REACT_APP_LINK_PRIVACY_POLICY_AR : process.env.REACT_APP_LINK_PRIVACY_POLICY_EN}`} target="_blank" rel="noreferrer">{getLanguageValues(lang, 'privacyPolicyText')}</a></li>
				</ul> */}
			</FooterBottomSectionContainer>
		</>
	)
}

export default Footer
