import styled from "styled-components";
import { pxToRem } from "../../../../Utils/helperFunctions";

export const ButtonContainerStyle = styled.div`
  margin-top: ${pxToRem(16)};
`;

export const ButtonStyle = styled.button`
  background-color: #f77860;
  width: 20%;
  display: inline-block;
  text-align: center;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  line-height: 2.5rem;
  transition: background 0.3s ease-in-out;

  &:disabled {
    background-color: ${(props) => props.theme.palette?.disabledRed || "#d3d3d3"};
    cursor: no-drop;
  }

  &:hover {
    background: #e56750; /* Slightly darker shade */
  }

  /* Tablets (iPads & iPad Mini) */
  @media (max-width: 1024px) {
    width: 40%;
  }

  /* iPad Mini (768px - 834px) */
  @media (min-width: 768px) and (max-width: 834px) {
    width: 50%;  /* Adjust width for iPad Mini */
  }

  /* Mobile Devices */
  @media (max-width: 767px) {
    width: 100% !important;
    margin: 0 !important;
  }
`;


