import styled from "styled-components";
import { desktopMedia } from "../../../../styles/themes/constants";

export const HeroBannerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px; /* Mobile height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top; /* Ensures top part is visible */
  }

  ${desktopMedia} {
    height: 600px; /* Desktop height */
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1;
`;

export const HeroContent = styled.div`
  position: absolute;
  text-align: center;
  color: #fff;
  z-index: 2;
  padding: 0 20px;
  bottom:10%;

  h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 16px;
    // font-weight: 300;
  }

  ${desktopMedia} {
    h1 {
      font-size: 50px;
    }

    h3 {
      font-size: 30px;
    }
  }
`;
