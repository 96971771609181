import React from "react";
import { ButtonContainerStyle, ButtonStyle } from "./style";

const Button = (props) => {
  const { fieldType, fieldLabel } = props.data;

  // Check if the field label is in Arabic (using a regex to check for Arabic characters)
  const isArabic = /[\u0600-\u06FF]/.test(fieldLabel);

  return (
    <ButtonContainerStyle>
      <ButtonStyle
        dir={isArabic ? 'rtl' : 'ltr'} // Conditionally pass 'rtl' or 'ltr' based on the label's language
        type={fieldType}
        onClick={props && props.onClick}
        disabled={props.disabled}
      >
        <strong>{fieldLabel}</strong>
      </ButtonStyle>
    </ButtonContainerStyle>
  );
};

export default Button;
