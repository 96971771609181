import styled from "styled-components";
import { pxToRem } from "../../../Utils/helperFunctions";
import { desktopMedia } from "../../../../styles/themes/constants";

export const AppContainer = styled.div`
  font-family: ${props => props.theme.typography.fontFamily.primaryRegular};
`;

export const HomeContainer = styled.div`
  ${desktopMedia} {
    background: ${props => props.theme.palette.lightYellow};
  }
  .text-container {
    line-height: 24px;
  }
`;

export const HomeTextContainer = styled.div`
  padding: ${pxToRem(24)} ${pxToRem(24)};
  
  ${desktopMedia} {
    padding: ${pxToRem(40)} ${pxToRem(80)} ${pxToRem(60)} ${pxToRem(80)};
    background: ${props => props.theme.mixins.White()};
  }
`;